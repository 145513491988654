import React from "react"
import styled from "styled-components"
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch"
import { Link } from "gatsby"
import blog from "../images/blogPexels.jpg"
import { graphql } from "gatsby"
import ReactPaginate from "react-paginate"
import { GatsbyImage } from "gatsby-plugin-image"
import certisuredIcon from "../images/icon.png"
import FormDialog from "../components/FormDialogcopy/FormDialog"
import FormControl from "@material-ui/core/FormControl"
import NativeSelect from "@material-ui/core/NativeSelect"
function Events({ data }) {
  const emptyQuery = ""
  const allPosts = data.allStrapiEvents.nodes
  //filtered Data
  const [state, setState] = React.useState({
    filteredData: [],
    query: emptyQuery,
    events : allPosts[0].type
  })



  //to set a variable
  const [category, setCategory] = React.useState("")
  const [tags, setTags] = React.useState("")

  //length of the nodes
  const [allTags, setAllTags] = React.useState(0)
  const [allCategory, setAllCategory] = React.useState(0)

  // to show category or tags or all
  //   const [show, setShow] = React.useState(true)
  const [lengthCheck, setLengthCheck] = React.useState(true)
  const [showCat, setShowCat] = React.useState(false)
  const [showTag, setShowTag] = React.useState(false)

  //Pagination
  const [offset, setOffset] = React.useState(0)
  const [perPage, setPerPage] = React.useState(6)
  const [pageCount, setPageCount] = React.useState(0)
  const [isDesktop, setIsDesktop] = React.useState(false)
  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    if (window.innerWidth > 769) {
      setPerPage(9)
    } else {
      setPerPage(8)
    }
  }, [])

  const length = data.allStrapiEvents.nodes.length

  React.useEffect(() => {
    setPageCount(Math.ceil(length / perPage))
  }, [offset, perPage, length])

  //   const handleTags = (tag) => {
  //     const length = data.allStrapiBlogTags.nodes.filter(
  //       (c) => c.tag_title === tag
  //     )
  //     setAllTags(length[0].blogs.length)
  //     setTags(tag)
  //     setShow(false)
  //     setShowCat(false)
  //     setShowTag(true)
  //     setCategory("")
  //     window.scrollTo({ behavior: "smooth", top: "0px" })
  //   }

  //   const handleCat = (cat) => {
  //     const length = data.allStrapiBlogsCategories.nodes.filter(
  //       (c) => c.category_name === cat
  //     )
  //     setAllCategory(length[0].blogs.length)
  //     setCategory(cat)
  //     setShow(false)
  //     setShowTag(false)
  //     setShowCat(true)
  //     setTags("")
  //     window.scrollTo({ behavior: "smooth", top: "0px" })
  //   }

  const handlePageClick = (e) => {
    const selectedPage = e.selected
    if (selectedPage === 0) {
      setOffset(selectedPage)
    } else {
      setOffset((selectedPage * perPage) % length)
    }
    window.scrollTo({ behavior: "smooth", top: "0px" })
  }

  //   const handleCatChange = (event) => {
  //     const query = event

  //     const posts = data.allStrapiBlogs.nodes || []
  //     const category = data.allStrapiBlogsCategories.nodes || []
  //     let filtered = []

  //     const filteredData = posts.map((post) => {
  //       const { title, shortDescrption, blogs_categories } = post
  //       return blogs_categories.map((cat) => {
  //         return (
  //           cat.category_name.toLowerCase() === event.toLowerCase() &&
  //           filtered.push(post)
  //         )
  //       })
  //     })
  //     window.scrollTo({ behavior: "smooth", top: "0px" })
  //     let length = filtered.length
  //     setPageCount(Math.ceil(length / perPage))
  //     setAllCategory(filtered.length)
  //     setShowCat(true)
  //     setShowTag(false)
  //     setLengthCheck(false)
  //     setState({
  //       query,
  //       filteredData: filtered,
  //     })
  //   }

  //   const handleTagChange = (event) => {
  //     const query = event

  //     const posts = data.allStrapiBlogs.nodes || []
  //     const category = data.allStrapiBlogsCategories.nodes || []
  //     let filtered = []

  //     const filteredData = posts.map((post) => {
  //       const { title, shortDescrption, blog_tags } = post
  //       return blog_tags.map((cat) => {
  //         return (
  //           cat.tag_title.toLowerCase() === event.toLowerCase() &&
  //           filtered.push(post)
  //         )
  //       })
  //     })
  //     window.scrollTo({ behavior: "smooth", top: "0px" })
  //     let length = filtered.length
  //     setPageCount(Math.ceil(length / perPage))
  //     setAllTags(filtered.length.length)
  //     setShowCat(false)
  //     setShowTag(true)
  //     setLengthCheck(false)
  //     setState({
  //       query,
  //       filteredData: filtered,
  //     })
  //   }
  const handleChange = (event) => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value,
    })
  }
  const handleInputChange = (event) => {
    const query = event.target.value

    const posts = data.allStrapiEvents.nodes || []

    const filteredData = posts.filter((post) => {
      const { title } = post
      const shortDescrption = title
      return (
        title.toLowerCase().includes(query.toLowerCase()) ||
        shortDescrption.toLowerCase().includes(query.toLowerCase())
      )
    })

    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state

  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

  return (
    <Container>
      <Wrapper>
        <div className="right_section">
          <div className="search__container">
            <input
              className="search__input"
              type="text"
              placeholder="search for events"
              onChange={handleInputChange}
            />
            <div className="search_icon">
              <AiOutlineSearch />
            </div>
          </div>
          <div className="heading">
            <SelectDay>
              <NativeSelection
                value={state.events}
                name="events"
                onChange={handleChange}
                inputProps={{ "aria-label": "events" }}
              >
                {allPosts.map((d, id) => (
                  <option value={d.type} key={id}>
                    {d.type}
                  </option>
                ))}
              </NativeSelection>
            </SelectDay>
          </div>
        </div>

        {/* blogs main container */}
        <div className="blogs_container_container">
          {posts.length ? (
            posts.slice(offset, offset + perPage).map(
              (d) =>
                d.type == state.events && (
                  <div className="blogs_container">
                    <Link to={`${d.link_to_landingPage}`}>
                      <div className="blog_image">
                        {d.image ? (
                          <img src={d.image.url} alt={d.title} />
                        ) : (
                          <img
                            style={{ width: "unset" }}
                            src={certisuredIcon}
                            alt=""
                          />
                        )}
                      </div>
                    </Link>
                    <div className="blogs_detail_section">
                      <h4 style={{ textTransform: "uppercase" }}>{d.title}</h4>
                      <div className="">
                        <p>Start time: {d.start_time}</p>
                        <p>End time: {d.end_time}</p>
                      </div>
                      <div className="register_now_section">
                        <p className="enrolled">
                          {d.people_registered} people have registered
                        </p>
                        <div className="register_button">
                          <FormDialog
                            slug="events"
                            formTitle={d.form_name}
                            buttonName="Register"
                            formName="Register for events"
                            type="Events Type"
                            formSubmitMessage="Form Submitted"
                            mailchimp={false}
                            normalMailchimp={true}
                            title="Register"
                            event={d.title}
                            image={d.image.url}
                            startsAt={d.start_time}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )
          ) : (
            <div className="no_posts">
              <span>
                No Events found for this search<b>{query}</b>
              </span>
            </div>
          )}
        </div>
        <div
          className="blog_paginate"
          style={posts.length ? { display: "block" } : { display: "none" }}
        >
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </Wrapper>
    </Container>
  )
}

export default Events

export const query = graphql`
  {
    allStrapiEvents {
      nodes {
        people_registered
        start_time
        register_now_button
        title
        end_time
        link_to_landingPage
        form_name
        type
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          url
        }
      }
    }
  }
`

const SelectDay = styled(FormControl)``
const NativeSelection = styled(NativeSelect)`
  select {
    font-size: 22px;
    @media (max-width: 991px) {
      font-size: 16px;
    }
  }
`
const Container = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  .heading {
    margin: 20px;
  }
`
const Wrapper = styled.div`
  width: 100%;
  grid-area: 1/2/2/11;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-gap: 50px;
  height: auto;
  margin-top: 20px;
  #lets-talk{
    min-width: auto !important;
    font-weight: 500 !important;
  }
  @media (max-width: 991px) {
    margin-top: 60px;
    grid-gap: 20px;
  }
  /* a::selection {
    background-color: transparent;
    color: transparent;
  } */
  button {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  button:focus {
    outline: none !important;
  }
  .right_section_section {
    grid-area: 1/2/2/3;
    position: relative;
    margin-bottom: 90px;

    @media (max-width: 991px) {
      grid-area: 4/1/5/3;
    }
  }
  .right_section {
    grid-area: 1/1/2/2;
    width: calc(100% - 50%);
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
      width: 100%;
      grid-area: 1/1/2/3;
    }
    .search__container {
      display: flex;
      flex-direction: row;
      background: #f8f8f8;
      box-shadow: 0px 2.70041px 2.70041px rgba(0, 0, 0, 0.1);
      border-radius: 69.3284px;
      /* @media (max-width: 991px) {
        display: none;
      } */
      .search__input {
        width: 100%;
        padding: 20px 24px;
        border: none;
        background-color: transparent;
        transition: transform 250ms ease-in-out;
        font-size: 14px;
        line-height: 18px;
        outline: none;
        color: var(--secondaryColor);

        backface-visibility: hidden;
      }
      .search_icon {
        display: flex;
        width: 70px;
        background-color: var(--purpleColor);
        justify-content: center;
        align-items: center;
        border-radius: 69.3284px;

        svg {
          color: #fff;
          font-size: 30px;
        }
      }
      .search__input::placeholder {
        color: rgba(87, 87, 86, 0.8) !important;
        text-transform: lowercase;
        letter-spacing: 1.5px;
      }
    }
    .categories_container_container {
      min-height: 300px;
      display: flex;
      background: #f8f8f8;
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      margin-top: 50px;
      @media (max-width: 991px) {
        min-height: 1px;
      }
      .categories_container {
        flex-direction: column;
        padding: 20px;
        h3 {
          font-size: 24px;
          font-weight: 600;
          letter-spacing: 0.03em;
          margin-left: 15px;
        }
      }
      .categories_links {
        display: flex;
        flex-wrap: wrap;

        button {
          min-width: 50px;
          margin: 15px 4px;
          text-decoration: none;
          color: var(--secondaryColor);
          transition: 0.2s ease-in-out;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.03em;
          outline: none;
          border: none;
          cursor: pointer;
          background: none;
          box-shadow: none;
          -webkit-tap-highlight-color: transparent;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        button:hover {
          color: var(--purpleColor);
          transform: scale(1.1);
        }
        button:active {
          color: black !important;
        }
        button:focus {
          color: black;
          outline: none;
        }
      }
    }
    .tags_container_container {
      display: flex;
      margin-top: 50px;
      min-height: 300px;
      background: #f8f8f8;
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      @media (max-width: 991px) {
        min-height: 1px;
      }
      .tags_container {
        flex-direction: column;
        padding: 20px;

        h3 {
          font-size: 24px;
          font-weight: 600;
          letter-spacing: 0.03em;
          margin-left: 15px;
        }
      }
      .tag_links {
        display: flex;
        flex-wrap: wrap;
        button {
          margin: 15px 4px;
          min-width: 50px;
          text-decoration: none;
          position: relative;
          padding: 15px;
          border: 1px solid var(--purpleColor);
          border-radius: 17.5px;
          transition: 0.3s ease;
          color: var(--secondaryColor);
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.03em;
          display: flex;
          justify-content: center;
          outline: none;
          cursor: pointer;
        }
        button:hover {
          font-weight: 600;
        }
        button:focus {
          color: black;
          font-weight: 600;
          border: none;
        }
      }
    }
  }

  .blogs_container_container {
    grid-area: 2/1/3/3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 100px;
    grid-column-gap: 50px;
    grid-template-rows: repeat(auto-fill);
    height: auto;
    width: 100%;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
      grid-area: 2/1/3/3;
      grid-row-gap: 55px;
      grid-template-rows: auto;
    }
    .search__container_mobile {
      grid-area: 1/1/2/3;
      display: none;
      flex-direction: row;
      background: #f8f8f8;
      box-shadow: 0px 2.70041px 2.70041px rgba(0, 0, 0, 0.1);
      border-radius: 69.3284px;
      @media (max-width: 991px) {
        display: flex;
      }
      .search__input {
        width: 100%;
        padding: 18px 24px;
        border: none;
        background-color: transparent;
        transition: transform 250ms ease-in-out;
        font-size: 14px;
        line-height: 18px;
        outline: none;
        color: var(--secondaryColor);

        backface-visibility: hidden;
      }
      .search_icon {
        display: flex;
        width: 70px;
        background-color: var(--purpleColor);
        justify-content: center;
        align-items: center;
        border-radius: 69.3284px;

        svg {
          color: #fff;
          font-size: 30px;
        }
      }
      .search__input::placeholder {
        color: rgba(87, 87, 86, 0.8) !important;
        text-transform: lowercase;
        letter-spacing: 1.5px;
      }
    }
    .blogs_detail_section {
      background: #f1f1f1;
      padding: 15px;
      h4 {
        margin: 0 !important;
        font-size: 20px;
        padding-bottom: 10px;
        font-size: 20px;
      }
      .register_now_section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid var(--secondaryColor);
        a {
          background-color: var(--thirdColor);
          padding: 10px;
          border-radius: 6px;
        }
      }
      p {
        font-size: 17px !important;
        margin: 0;
      }
    }
    a {
      text-decoration: none;
      color: #000000;
      transition: 1s ease-in-out;
      outline: none;
      -webkit-tap-highlight-color: transparent;
    }
    a::selection {
      background-color: transparent;
    }

    a:focus {
      @media (max-width: 479px) {
        color: transparent;
        background-color: transparent;
        outline: none;
      }
    }

    a:active {
      h4 {
        color: var(--purpleColor);
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      transition: 0.2s ease-in-out;
      object-fit: cover;
    }
    a:hover {
      .blog_image {
        transform: scale(1);
      }
    }
    .blogs_container {
      grid-area: auto;
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      @media (max-width: 767px) {
        grid-area: auto/1/auto/3;
      }
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #000000;
        margin-bottom: 0;
        transition: 0.3s ease-out;
        color: var(--purpleColor);
        @media (max-width: 479px) {
          font-size: 14px;
        }
      }
      p {
        font-weight: 500;
        font-size: 24px;
        letter-spacing: 0.045em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @media (max-width: 479px) {
          font-size: 20px;
        }
      }
      .blog_image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 280px;
        transition: 0.5s ease-in-out;
        transform: scale(0.975);
        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
        }
        @media (max-width: 479px) {
          transform: scale(1);
          height: 305px;
        }
      }
    }
  }
  .no_posts {
    width: 100%;
    height: 60vh;
    grid-area: 1/1/2/4;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      font-weight: 700;
      padding: 0 5px;
      margin: 0;
    }
    b {
      padding: 0 5px;
    }
  }
  .blog_paginate {
    grid-area: 3/1/4/2;

    @media (max-width: 991px) {
      grid-area: 3/1/4/3;
      display: flex;
      flex-wrap: wrap;
    }
    li {
      margin-left: 14px;
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
    ul {
      padding-left: 0px;
    }
    a {
      border-radius: 6px !important;
    }
    .pagination {
      margin: 15px auto;
      display: flex;
      list-style: none;
      outline: none;
      flex-wrap: wrap;
      height: 100px;
    }
    .pagination > .active > a {
      background-color: var(--purpleColor);
      border-color: var(--purpleColor);
      color: #fff;
    }
    .pagination > li > a {
      border: 1px solid var(--purpleColor);
      padding: 8px 20px;
      outline: none;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;
      color: var(--secondaryColor);
      border-radius: 6px;
    }
    .pagination > .active > a,
    .pagination > .active > span,
    .pagination > .active > a:hover,
    .pagination > .active > span:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span:focus {
      background-color: var(--purpleColor);
      border-color: var(--purpleColor);
      outline: none;
    }
    .pagination > li > a,
    .pagination > li > span {
      color: var(--secondaryColor);
    }
    .pagination > li:first-child > a,
    .pagination > li:first-child > span,
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border-radius: unset;
    }
  }
`
